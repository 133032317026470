:root {
  --background: #f6f8ff;
  --background-initial: #f6f8ff;
  --background-content: #fefefe;
  --text-primary: #6b46c1;
  --text-secondary: #2b3442;
  --button-save: #6b46c1;
}

[data-theme="dark"] {
  --background: #0d1232;
  --background-content: #0e163a;
  --text-primary: #fff;
  --text-secondary: #fff;
  --button-save: #6b46c1;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

body {
  background: var(--background);
  color: var(--text-color);
}

.wrapper {
  display: flex;
}

::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}
